// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/uprate-secondary-button.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/uprate-secondary-button.tsx");
  import.meta.hot.lastModified = "1712947911928.2556";
}
// REMIX HMR END

import { Button, useColorModeValue } from "@chakra-ui/react";
import { UprateB3, UprateB4, UprateB5 } from "./uprate-styles";
export default function UprateSecondaryButton({
  children,
  textSize,
  ...rest
}) {
  _s();
  const hoverColor = useColorModeValue("purple.100", "purple.900");
  const hoverBorderColor = useColorModeValue("purple.200", "purple.800");
  return <Button borderWidth={1} borderColor={useColorModeValue("white", "gray.900")} backgroundColor={useColorModeValue("white", "gray.900")} borderRadius={"sm"} borderTopLeftRadius={"lg"} borderBottomRightRadius={"lg"} size={"sm"} _hover={{
    borderColor: hoverBorderColor,
    bgColor: hoverColor
  }} variant={"outline"} {...rest}>
      {textSize && textSize === "xs" ? <UprateB5>{children}</UprateB5> : textSize === "lg" ? <UprateB3>{children}</UprateB3> : <UprateB4>{children}</UprateB4>}
    </Button>;
}
_s(UprateSecondaryButton, "v/MoR/k+VZ2iX96+wBHloGLxkJ4=", false, function () {
  return [useColorModeValue, useColorModeValue, useColorModeValue, useColorModeValue];
});
_c = UprateSecondaryButton;
var _c;
$RefreshReg$(_c, "UprateSecondaryButton");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;